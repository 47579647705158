import { Link } from "react-router-dom";
import { Image } from "lucide-react";
import FocusImageCards, { type Card } from "~/components/FocusImageCards";
import Pages from "~/lib/constants/Pages";

export default function Gallery() {
  const imageCards: Card[] = [
    {
      title: "Image 1",
      src: "gallery/pic1.jpeg",
    },
    {
      title: "Image 2",
      src: "gallery/pic2_small.jpeg",
    },
    {
      title: "Image 3",
      src: "gallery/pic3_small.jpeg",
    },
    {
      title: "Image 4",
      src: "gallery/pic4_small.jpeg",
    },
    {
      title: "Image 5",
      src: "gallery/pic5_small.jpeg",
    },
    {
      title: "Image 6",
      src: "gallery/pic6.jpeg",
    },
    {
      title: "Image 7",
      src: "gallery/pic7_small.jpeg",
    },
    {
      title: "Image 8",
      src: "gallery/pic8_small.jpeg",
    },
    {
      title: "Image 9",
      src: "gallery/pic9_small.jpeg",
    },
  ];

  return (
    <>
      <div className="dark bg-[#222] px-4 py-16 text-neutral-100">
        <div className="mx-auto flex max-w-7xl items-start justify-between">
          <div>
            <h1 className="mb-4 text-4xl font-bold md:text-5xl">Gallery</h1>
            <p className="text-base text-neutral-400">
              <Link
                to={Pages.HOME}
                className="transition-colors hover:text-neutral-300"
              >
                Home
              </Link>{" "}
              / <span className="font-bold">Gallery</span>
            </p>
          </div>
          <div className="flex h-24 w-24 items-center justify-center rounded-t-[100%] rounded-bl-xl rounded-br-[100%] bg-[#434343] p-4">
            <Image className="h-[80%] w-[80%]" />
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 py-16">
        <FocusImageCards
          cards={imageCards.map((card) => ({
            ...card,
            style: {
              width: "auto",
              height: "auto",
              maxWidth: "100%",
              maxHeight: "100%",
            },
          }))}
        />
      </div>
    </>
  );
}
