interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export default function ServiceCard({
  icon,
  title,
  description,
}: ServiceCardProps) {
  return (
    <div className="group flex flex-col items-center p-4 text-center md:border-l-2 md:first:border-l-0">
      <div className="mb-4 rounded-t-[100%] rounded-bl-xl rounded-br-[100%] bg-zinc-900 p-4 text-white transition-transform duration-300 group-hover:scale-90">
        {icon}
      </div>
      <h4 className="mb-2 text-xl font-semibold">{title}</h4>
      <p className="text-neutral-600">{description}</p>
    </div>
  );
}
