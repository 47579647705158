import { useState } from "react";
import { Link } from "react-router-dom";

import { Gem, Info } from "lucide-react";

import Pages from "~/lib/constants/Pages";
import { cn } from "~/lib/utils/cn";

export default function Services() {
  const services: {
    name: string;
    description: string;
    price: number | string;
  }[] = [
    {
      name: "Women's Haircut",
      description:
        "Enjoy a customized haircut designed to suit your face shape and personal style, ensuring you leave the salon with a look that feels just right.",
      price: "35+",
    },
    {
      name: "Partial Highlights",
      description:
        "Add dimension and brightness to your hair with partial highlights, strategically placed to enhance your natural look.",
      price: "160+",
    },
    {
      name: "Half Highlights",
      description:
        "Perfect for a sun-kissed effect, half highlights create a natural glow by focusing on the upper layers of your hair.",
      price: "180+",
    },
    {
      name: "Full Highlights",
      description:
        "Achieve a complete transformation with full highlights, bringing depth and contrast to your entire hairstyle.",
      price: "225+",
    },
    {
      name: "Partial Balayage",
      description:
        "For a soft, natural gradient, partial balayage offers subtle lightening that mimics the sun's effect on your hair.",
      price: 170,
    },
    {
      name: "Full Balayage",
      description:
        "Experience a complete balayage treatment, giving your hair a beautiful, seamless blend from roots to tips.",
      price: 240,
    },
    {
      name: "Root Touch Up",
      description:
        "Maintain your color's vibrancy and hide regrowth with a root touch-up that ensures your hair looks flawless.",
      price: "65+",
    },
    {
      name: "All Over Color",
      description:
        "Refresh your look with a full head color application, providing even coverage and rich, lasting results.",
      price: "120+",
    },
    {
      name: "All Over Toning",
      description:
        "Neutralize unwanted tones and add shine to your hair with an all-over toning treatment, perfect for keeping your color fresh.",
      price: 60,
    },
    {
      name: "Up-Do",
      description:
        "Get ready for any special occasion with an elegant up-do that perfectly complements your outfit and style.",
      price: "70+",
    },
    {
      name: "Makeup Application",
      description:
        "Complete your look with professional makeup application, tailored to enhance your natural beauty for any event.",
      price: 70,
    },
    {
      name: "Extension Install",
      description:
        "Transform your hair with expertly installed extensions, adding length, volume, and fullness with seamless results.",
      price: "120 / row",
    },
    {
      name: "Move Up",
      description:
        "Keep your extensions looking flawless with our move-up service, ensuring they stay securely in place and blend beautifully.",
      price: "100 / row",
    },
    {
      name: "Bead-Only Move Up",
      description:
        "Maintain your bead extensions with our move-up service, repositioning them for a fresh, natural appearance.",
      price: 55,
    },
    {
      name: "Blowout",
      description:
        "Achieve smooth, polished hair with a blowout that adds volume and shine, perfect for any occasion.",
      price: "55+",
    },
    {
      name: "Silk Press",
      description:
        "Get sleek, straight hair with a silk press that smooths and softens your natural texture for a glossy, luxurious finish.",
      price: "85+",
    },
    {
      name: "Brazilian Blowout",
      description:
        "Tame frizz and enhance shine with a Brazilian blowout, providing long-lasting smoothness and manageability.",
      price: "225+",
    },
    {
      name: "GK Keratin Treatment",
      description:
        "Strengthen and smooth your hair with a GK keratin treatment, leaving it soft, shiny, and free of frizz.",
      price: "265+",
    },
    {
      name: "Deep Conditioning Treatment",
      description:
        "Revitalize dry, damaged hair with a deep conditioning treatment that nourishes and repairs for a healthy, lustrous finish.",
      price: 65,
    },
    {
      name: "Scalp Treatment",
      description:
        "Promote a healthy scalp with our specialized treatment, designed to soothe, exfoliate, and invigorate for optimal hair growth.",
      price: 85,
    },
  ];

  const ServiceCard = ({
    name,
    description,
    price,
  }: {
    name: string;
    description: string;
    price: number | string;
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className={cn(
          "relative overflow-hidden rounded-3xl bg-neutral-200/50 p-6 transition-colors duration-300 ease-in-out",
          "before:absolute before:inset-0 before:-translate-x-[101%] before:transform before:bg-neutral-900 before:transition-transform before:duration-300 before:ease-in-out before:content-['']",
          isHovered && "before:translate-x-0",
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative z-10">
          <div className="mb-4 flex items-start justify-between">
            <div>
              <h3
                className={cn(
                  "mb-2 text-xl font-semibold transition-colors duration-300 ease-in-out",
                  isHovered ? "text-neutral-100" : "text-neutral-900",
                )}
              >
                {name}
              </h3>
              <p
                className={cn(
                  "text-sm transition-colors duration-300 ease-in-out",
                  isHovered ? "text-neutral-300" : "text-neutral-700",
                )}
              >
                {description}
              </p>
            </div>
            <div
              className={cn(
                "whitespace-nowrap rounded-full px-3 py-1 text-sm font-semibold transition-colors duration-300 ease-in-out",
                isHovered
                  ? "bg-neutral-100 text-neutral-900"
                  : "bg-neutral-900 text-neutral-100",
              )}
            >
              ${price}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dark bg-[#222] px-4 py-16 text-neutral-100">
        <div className="mx-auto flex max-w-7xl items-start justify-between">
          <div>
            <h1 className="mb-4 text-4xl font-bold md:text-5xl">Services</h1>
            <p className="text-base text-neutral-400">
              <Link
                to={Pages.HOME}
                className="transition-colors hover:text-neutral-300"
              >
                Home
              </Link>{" "}
              / <span className="font-bold">Services</span>
            </p>
          </div>
          <div className="flex h-24 w-24 items-center justify-center rounded-t-[100%] rounded-bl-xl rounded-br-[100%] bg-[#434343] p-4">
            <Gem className="h-[80%] w-[80%]" />
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 py-16">
        <div className="flex flex-wrap justify-center gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="w-full max-w-[400px] sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)]"
            >
              <ServiceCard {...service} />
            </div>
          ))}
        </div>

        <div className="mt-12 rounded-lg bg-neutral-100 p-4 shadow-md">
          <div className="flex items-center space-x-3">
            <Info className="mt-1 h-6 w-6 flex-shrink-0 text-neutral-600" />
            <p className="text-sm text-neutral-600">
              <span className="font-semibold">Please note:</span> All prices are
              subject to change based on factors including but not limited to
              hair length, density, previous services, amount of product used,
              and/or desired look. Final pricing will be determined during
              consultation.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
