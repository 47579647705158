import { Link } from "react-router-dom";

import { HelpCircle } from "lucide-react";

import ScrollReveal from "~/components/ScrollReveal";
import { Button } from "~/components/ui/button";
import Pages from "~/lib/constants/Pages";

export default function About_Us() {
  return (
    <>
      <div className="dark bg-[#222] px-4 py-16 text-neutral-100">
        <div className="mx-auto flex max-w-7xl items-start justify-between">
          <div>
            <h1 className="mb-4 text-4xl font-bold md:text-5xl">About Us</h1>
            <p className="text-base text-neutral-400">
              <Link
                to={Pages.HOME}
                className="transition-colors hover:text-neutral-300"
              >
                Home
              </Link>{" "}
              / <span className="font-bold">About Us</span>
            </p>
          </div>
          <div className="flex h-24 w-24 items-center justify-center rounded-t-[100%] rounded-bl-xl rounded-br-[100%] bg-[#434343] p-4">
            <HelpCircle className="h-[80%] w-[80%]" />
          </div>
        </div>
      </div>

      <ScrollReveal>
        <section className="container max-w-7xl px-6 py-12">
          <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2">
            <div className="relative">
              <div className="relative h-[300px] w-full overflow-hidden rounded-br-[100px] rounded-tl-[100px] md:h-[400px]">
                <img
                  src="./aboutus.jpeg"
                  alt="Salon interior"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            <div className="mt-20 md:mt-0">
              <h2 className="mb-4 text-sm font-semibold uppercase tracking-wide">
                ABOUT US
              </h2>

              <div className="mb-4 flex flex-col gap-0.5 text-3xl font-bold md:gap-2 md:text-5xl">
                <div className="flex w-full flex-row gap-2 text-left md:gap-4">
                  <h3>Salon</h3>
                  <h3>Where</h3>
                </div>

                <div className="flex w-full flex-row gap-2 text-left md:gap-4">
                  <h3>You</h3>
                  <h3>Will</h3>
                  <h3>Feel</h3>
                  <h3>Unique</h3>
                </div>
              </div>

              <p className="mb-8 text-neutral-600">
                We are based in Allen, Texas and proudly cater to Collin County
                and its neighboring areas. Katie & Crystal are experts in Razor
                Cuts, Brazilian Blowouts, straightening treatmeants, Extensions,
                Highlights, Blonding, Color, and Vivids. Out priority at Studio
                147 is YOU! We do our absolute best to ensure we maintain the
                health and integrity of your hair. We treat each client like
                family, sharing stories and laughter along the way!
              </p>

              {/*
              <div className="mb-8">
                <div className="flex items-center border-b border-neutral-200 pb-2">
                  <span className="mr-2 text-lg font-bold">01.</span>
                  <p className="font-semibold text-neutral-600">
                    The hair cutting and styling with 10 years of experience.
                  </p>
                </div>

                <div className="flex items-center border-b border-neutral-200 py-2">
                  <span className="mr-2 text-lg font-bold">02.</span>
                  <p className="font-semibold text-neutral-600">
                    Update the latest technology and tendency in the world.
                  </p>
                </div>

                <div className="flex items-center py-2">
                  <span className="mr-2 text-lg font-bold">03.</span>
                  <p className="font-semibold text-neutral-600">
                    Using the best products from the top providers.
                  </p>
                </div>
              </div>
              */}
              <div>
                <Link to={Pages.BOOKING_LINK} target="_blank">
                  <Button variant="outline" className="rounded-full border-2">
                    Book Now
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </ScrollReveal>
    </>
  );
}
