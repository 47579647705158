import React, { useEffect, useRef } from "react";

import { motion, useAnimation, useInView } from "framer-motion";

import { cn } from "~/lib/utils/cn";

interface ScrollRevealProps {
  children: React.ReactNode;
  width?: "fit-content" | "100%";
  className?: string;
}

export default function ScrollReveal({
  children,
  width = "fit-content",
  className,
  ...props
}: ScrollRevealProps) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-25% 0% -25% 0%" });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      className={cn(
        "relative overflow-hidden",
        { "w-full": width === "100%" },
        { "w-auto": width === "fit-content" },
        className,
      )}
      initial="hidden"
      animate={controls}
      {...props}
    >
      {children}
    </motion.div>
  );
}
