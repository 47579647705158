import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Pages from "~/lib/constants/Pages";
import About_Us from "~/pages/about-us";
import Gallery from "~/pages/gallery";
import Home from "~/pages/home";
import Not_Found from "~/pages/not-found";
import Services from "~/pages/services";
import Studio from "~/pages/studio";

import "~/globals.css";

const getBasename = () => {
  const { hostname } = window.location;
  if (hostname === "stg.codermerlin.com")
    return "/studio147atwatterscreek.com/";
  return "/";
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={Pages.ROOT} element={<Studio />}>
      <Route index element={<Home />} />
      <Route path={Pages.HOME} element={<Home />} />
      <Route path={Pages.ABOUT} element={<About_Us />} />
      <Route path={Pages.SERVICES} element={<Services />} />
      <Route path={Pages.GALLERY} element={<Gallery />} />

      <Route path="*" element={<Not_Found />} />
    </Route>,
  ),
  { basename: getBasename() },
);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
