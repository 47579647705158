import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { ArrowRight, Palette, Scissors, Sparkles } from "lucide-react";

import ScrollReveal from "~/components/ScrollReveal";
import ServiceCard from "~/components/ServiceCard";
import { Button } from "~/components/ui/button";
import Pages from "~/lib/constants/Pages";

export default function Home() {
  const fadeInUp = (duration = 0.5, delay = 0) => ({
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration, delay } },
  });

  const fadeInLeft = (duration = 0.5, delay = 0) => ({
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0, transition: { duration, delay } },
  });

  {
    /* const fadeInRight = (duration = 0.5, delay = 0) => ({
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration, delay } },
  });*/
  }

  return (
    <>
      {/* Hero */}
      <ScrollReveal
        width="100%"
        className="dark flex h-full flex-grow items-center bg-[#222] text-neutral-100"
      >
        <main className="container mx-auto flex h-full max-w-7xl flex-col items-center px-6 py-12 md:flex-row">
          <motion.div className="mb-8 md:mb-0 md:w-1/2">
            <motion.h2
              variants={fadeInUp(1)}
              className="mb-4 text-sm font-semibold uppercase tracking-wide"
            >
              WELCOME TO STUDIO 147 @ WATTERS CREEK
            </motion.h2>

            {/* Title */}
            <motion.div className="mb-4 flex flex-col gap-0.5 text-3xl font-bold md:gap-2 md:text-5xl">
              <div className="flex w-full flex-row gap-2 text-left md:gap-4">
                <motion.h1 variants={fadeInLeft(1, 0.25)}>Discover</motion.h1>
                <motion.h1 variants={fadeInLeft(1, 0.3)}>Your</motion.h1>
              </div>

              <motion.div className="flex w-full flex-row gap-2 text-left md:gap-4">
                <motion.h1 variants={fadeInLeft(1, 0.35)}>Unique</motion.h1>
              </motion.div>

              <motion.div className="flex w-full flex-row gap-2 text-left md:gap-4">
                <motion.h1 variants={fadeInLeft(1, 0.45)}>New</motion.h1>
                <motion.h1 variants={fadeInLeft(1, 0.5)}>Style</motion.h1>
              </motion.div>
            </motion.div>

            <motion.p
              variants={fadeInUp(1, 0.5)}
              className="mb-4 text-neutral-400"
            >
              We believe that your hair is an expression of your personality.
              Our skilled stylists are here to help you unleash your true style
              potential.
            </motion.p>

            <motion.div variants={fadeInUp(1, 0.75)}>
              <Link to={Pages.SERVICES}>
                <Button
                  variant="expandIcon"
                  iconPlacement="right"
                  icon={<ArrowRight size={20} strokeWidth={1} />}
                  className="mt-4 md:mt-6"
                >
                  View Services
                </Button>
              </Link>
            </motion.div>
          </motion.div>

          <motion.div className="md:w-1/2" variants={fadeInUp(1, 0.75)}>
            <div className="relative flex items-center justify-end">
              <img
                alt="Hairstyling scene"
                className="rounded-t-[100%] rounded-bl-[10%] rounded-br-[100%]"
                height="500"
                src="./studio147.jpeg"
                style={{
                  aspectRatio: "500/500",
                  objectFit: "cover",
                }}
                width="500"
              />
            </div>
          </motion.div>
        </main>
      </ScrollReveal>

      {/* About */}
      {/*
      <ScrollReveal>
        <section className="container max-w-7xl px-6 py-12">
          <div className="grid grid-cols-1 items-center gap-8 md:grid-cols-2">
            <motion.div variants={fadeInRight(1, 0.25)} className="relative">
              <div className="relative h-[300px] w-full overflow-hidden rounded-br-[100px] rounded-tl-[100px] md:h-[400px]">
                <img
                  src="./hero_2.webp"
                  alt="Salon interior"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </motion.div>

            
            <div className="mt-20 md:mt-0">
              <motion.h2
                variants={fadeInUp(1)}
                className="mb-4 text-sm font-semibold uppercase tracking-wide"
              >
                ABOUT US
              </motion.h2>

              <motion.div className="mb-4 flex flex-col gap-0.5 text-3xl font-bold md:gap-2 md:text-5xl">
                <div className="flex w-full flex-row gap-2 text-left md:gap-4">
                  <motion.h3 variants={fadeInLeft(1, 0.25)}>Salon</motion.h3>
                  <motion.h3 variants={fadeInLeft(1, 0.3)}>Where</motion.h3>
                </div>

                <motion.div className="flex w-full flex-row gap-2 text-left md:gap-4">
                  <motion.h3 variants={fadeInLeft(1, 0.35)}>You</motion.h3>
                  <motion.h3 variants={fadeInLeft(1, 0.4)}>Will</motion.h3>
                  <motion.h3 variants={fadeInLeft(1, 0.45)}>Feel</motion.h3>
                  <motion.h3 variants={fadeInLeft(1, 0.5)}>Unique</motion.h3>
                </motion.div>
              </motion.div>

              <motion.p
                variants={fadeInUp(1, 0.7)}
                className="mb-8 text-neutral-600"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy
              </motion.p>

              <motion.div variants={fadeInUp(1, 0.8)} className="mb-8">
                <div className="flex items-center border-b border-neutral-200 pb-2">
                  <span className="mr-2 text-lg font-bold">01.</span>
                  <p className="font-semibold text-neutral-600">
                    The hair cutting and styling with 10 years of experience.
                  </p>
                </div>

                <div className="flex items-center border-b border-neutral-200 py-2">
                  <span className="mr-2 text-lg font-bold">02.</span>
                  <p className="font-semibold text-neutral-600">
                    Update the latest technology and tendency in the world.
                  </p>
                </div>

                <div className="flex items-center py-2">
                  <span className="mr-2 text-lg font-bold">03.</span>
                  <p className="font-semibold text-neutral-600">
                    Using the best products from the top providers.
                  </p>
                </div>
              </motion.div>

              <motion.div variants={fadeInUp(1, 0.9)}>
                <Button variant="outline" className="rounded-full border-2">
                  Read More
                </Button>
              </motion.div>
            </div>
            
          </div>
        </section>
      </ScrollReveal>
      */}

      {/* Services */}
      <ScrollReveal>
        <section className="w-full bg-[#f5f5f5] px-6 py-12">
          <div className="container max-w-7xl">
            <div className="mb-12 text-center">
              <motion.h2
                variants={fadeInUp()}
                className="mb-4 text-sm font-semibold uppercase tracking-wide text-neutral-600"
              >
                PROFESSIONAL SERVICES
              </motion.h2>

              <div className="flex flex-row justify-center gap-2 text-center text-3xl font-bold md:gap-4 md:text-5xl">
                <motion.h3 variants={fadeInLeft(1, 0.25)}>We&apos;re</motion.h3>
                <motion.h3 variants={fadeInLeft(1, 0.3)}>Experienced</motion.h3>
                <motion.h3 variants={fadeInLeft(1, 0.35)}>With</motion.h3>
              </div>
            </div>

            <motion.div
              variants={fadeInUp(1, 0.5)}
              className="flex flex-col md:flex-row"
            >
              <ServiceCard
                icon={<Scissors className="h-8 w-8" />}
                title="Cutting & Styling"
                description="Our expert stylists provide precision haircuts and creative styling to enhance your unique look. Whether you're after a classic cut or something more contemporary, we'll ensure you leave looking and feeling your best."
              />
              <ServiceCard
                icon={<Sparkles className="h-8 w-8" />}
                title="Hair Treatments"
                description="Revitalize your hair with our range of luxurious treatments. From deep conditioning to scalp therapy, our services are designed to nourish and restore your hair's natural beauty."
              />
              <ServiceCard
                icon={<Palette className="h-8 w-8" />}
                title="Hair Coloring"
                description="Transform your look with our professional coloring services. Whether you're looking for subtle highlights, bold color changes, or root touch-ups, our color specialists will create the perfect shade just for you."
              />
            </motion.div>

            <motion.div
              variants={fadeInUp(1, 0.75)}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link to={Pages.SERVICES}>
                <Button
                  variant="expandIcon"
                  iconPlacement="right"
                  icon={<ArrowRight size={20} strokeWidth={1} />}
                  className="mt-4 md:mt-6"
                >
                  View Services
                </Button>
              </Link>
            </motion.div>
          </div>
        </section>
      </ScrollReveal>
    </>
  );
}
