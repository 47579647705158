import { useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { Clock, MapPin, Phone } from "lucide-react";

import Header from "~/components/Header";
import FacebookIcon from "~/components/icons/FacebookIcon";
//import InstagramIcon from "~/components/icons/InstagramIcon";
//import XIcon from "~/components/icons/XIcon";
import Pages from "~/lib/constants/Pages";

export default function Studio() {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const headerRef = useRef(null);

  // Show sticky header when the header is not in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowStickyHeader(!entry.isIntersecting);
      },
      { threshold: 0.0 },
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const SocialIcon = ({
    icon,
    href,
  }: {
    icon: React.ReactNode;
    href: string;
  }) => {
    return (
      <a
        href={href}
        className="flex h-10 w-10 items-center justify-center rounded-t-[100%] rounded-bl-[100%] rounded-br-xl bg-neutral-700 p-2 transition-colors hover:bg-neutral-600"
      >
        {icon}
      </a>
    );
  };

  return (
    <>
      <div className="min-h-dvh">
        <div
          ref={headerRef}
          className="dark flex justify-center border-b border-neutral-600 bg-[#222] text-neutral-100"
        >
          <Header />
        </div>

        {showStickyHeader && <Header isSticky />}

        <Outlet />

        <footer className="dark bg-[#222] text-neutral-100">
          <div className="mx-auto max-w-7xl px-4 py-8">
            <div className="mb-8 grid grid-cols-1 gap-8 md:grid-cols-3">
              <div className="text-center">
                <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-zinc-900">
                  <MapPin className="h-6 w-6" />
                </div>
                <h3 className="mb-2 text-lg font-semibold">Our Location</h3>
                <p className="text-neutral-400">905 Watters Creek #200</p>
                <p className="text-neutral-400">Allen, Texas 75013</p>
                <p className="text-neutral-400">United States</p>
              </div>
              <div className="text-center">
                <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-zinc-900">
                  <Phone className="h-6 w-6" />
                </div>
                <h3 className="mb-2 text-lg font-semibold">Get in Touch</h3>
                <p className="text-neutral-400">Phone: (214) 846-6385</p>
              </div>
              <div className="text-center">
                <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-zinc-900">
                  <Clock className="h-6 w-6" />
                </div>
                <h3 className="mb-2 text-lg font-semibold">Working Hours</h3>
                <p className="text-neutral-400">Monday: 9:00 AM - 8:00 PM</p>
                <p className="text-neutral-400">Tue-Fri: 7:00 AM - 9:00 PM</p>
                <p className="text-neutral-400">Sat: 7:00 AM - 6:00 PM</p>
                <p className="text-neutral-400">Sun: Closed</p>
              </div>
            </div>
          </div>

          <hr className="border-neutral-600" />

          <div className="mx-auto max-w-7xl px-4 py-8">
            <div className="flex flex-col items-center justify-between md:flex-row">
              <div className="mb-4 md:mb-0">
                <h2 className="text-center text-2xl font-bold">Studio 147</h2>
                <div className="mt-4 flex justify-center space-x-4 md:justify-start">
                  <SocialIcon
                    icon={<FacebookIcon size={20} fill="white" />}
                    href="https://www.facebook.com/people/Studio-147-at-Watters-Creek/61556969531738/"
                  />
                  {/*<SocialIcon
                    icon={<InstagramIcon size={20} fill="white" />}
                    href="#"
                  />
                  <SocialIcon
                    icon={<XIcon size={20} fill="white" />}
                    href="#"
                  />*/}
                </div>
              </div>

              <div className="flex flex-col space-y-2 md:space-y-0">
                <nav className="mb-4 flex flex-wrap justify-center space-x-6 md:mb-0 md:justify-end">
                  <Link to={Pages.HOME} className="hover:text-neutral-300">
                    Home
                  </Link>
                  <Link to={Pages.ABOUT} className="hover:text-neutral-300">
                    About Us
                  </Link>
                  <Link to={Pages.SERVICES} className="hover:text-neutral-300">
                    Services
                  </Link>
                </nav>

                <p className="!mt-4 text-sm text-neutral-400">
                  Made with ❤️ by{" "}
                  <Link
                    to="https://www.cs-squared.org/"
                    className="text-neutral-200 hover:underline"
                  >
                    CS-Squared
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
