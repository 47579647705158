import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { motion } from "framer-motion";
import { Menu } from "lucide-react";

import { Button } from "~/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "~/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet";
import Pages from "~/lib/constants/Pages";
import { cn } from "~/lib/utils/cn";

interface HeaderProps {
  isSticky?: boolean;
}

const navigationItems = [
  { title: "Home", href: Pages.HOME },
  { title: "About Us", href: Pages.ABOUT },
  { title: "Services", href: Pages.SERVICES },
  { title: "Gallery", href: Pages.GALLERY },
];

export default function Header({ isSticky = false }: HeaderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <motion.header
      initial={isSticky ? { y: -100 } : false}
      animate={isSticky ? { y: 0 } : false}
      transition={{ duration: 0.3 }}
      className={cn(
        "dark flex h-16 w-full items-center justify-center px-6 py-4",
        isSticky &&
          "fixed left-0 right-0 top-0 z-50 bg-[#222] text-neutral-100 shadow-md",
      )}
    >
      <div className="flex w-full max-w-7xl items-center justify-between">
        <div className="text-2xl font-bold">STUDIO 147</div>
        <NavigationMenu className="hidden md:flex">
          <NavigationMenuList className="flex space-x-4">
            {navigationItems.map((item) => (
              <NavigationMenuItem key={item.title}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    cn(
                      "rounded-md px-3 py-2 font-medium transition-colors",
                      isActive
                        ? "text-neutral-100"
                        : "text-neutral-400 hover:text-neutral-200",
                    )
                  }
                >
                  {item.title}
                </NavLink>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>

        <div className="hidden md:block">
          <Link to={Pages.BOOKING_LINK} target="_blank">
            <Button variant="gooeyLeft" className="transition-colors">
              Book Now
            </Button>
          </Link>
        </div>
      </div>

      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="md:hidden">
            <Menu className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="right"
          className="dark w-[300px] bg-background text-foreground"
        >
          <nav className="flex flex-col space-y-4">
            {navigationItems.map((item) => (
              <Link
                key={item.title}
                to={item.href}
                onClick={() => setIsOpen(false)}
              >
                {item.title}
              </Link>
            ))}
            <Link to={Pages.BOOKING_LINK} target="_blank">
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Book Now
              </Button>
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
    </motion.header>
  );
}
