import { Link } from "react-router-dom";

import { OctagonX } from "lucide-react";

import Pages from "~/lib/constants/Pages";

export default function Not_Found() {
  return (
    <>
      <div className="dark bg-[#222] px-4 py-16 text-neutral-100">
        <div className="mx-auto flex max-w-7xl items-start justify-between">
          <div>
            <h1 className="mb-4 text-4xl font-bold md:text-5xl">Not Found</h1>
            <p className="text-base text-neutral-400">
              <Link
                to={Pages.HOME}
                className="transition-colors hover:text-neutral-300"
              >
                Home
              </Link>{" "}
              / <span className="font-bold">Not Found</span>
            </p>
          </div>
          <div className="flex h-24 w-24 items-center justify-center rounded-t-[100%] rounded-bl-xl rounded-br-[100%] bg-[#434343] p-4">
            <OctagonX className="h-[80%] w-[80%]" />
          </div>
        </div>
      </div>
    </>
  );
}
